<template>
  <div>
    <router-link  v-if="venue.slug"
      :to="{ path: venue.slug  }"
      class="btn-link btn-sm"
      :style="{fontSize: this.fontSize}"
    >
      <span v-if="!noIcon" class="tim-icons icon-square-pin" style="font-size: 20px;background-color: #e21a6a;color: black;border-radius: 100px;">
      </span>
      {{ venue.title.toUpperCase() }}
    </router-link>
    <p  v-if="!venue.slug">
      <span v-if="!noIcon" class="tim-icons icon-square-pin" style="font-size: 20px;margin: 4px;background-color: #e21a6a;color: black;border-radius: 100px;"></span>
      {{ venue.title.toUpperCase() }}
    </p>
  </div>
</template>
<script>
import {venueDecoder} from "@/common/Decoder";

export default {
  name: 'EventVenue',
  props: {
    title: {
      type: String,
      description: 'Event title',
    },
    noIcon: {
      type: Boolean,
      description: 'use Icon',
      default: false,
    },
    image: {
      type:String,
      description: 'Image',
      default: '',
    },
    fontSize: {
      type: String,
      description: 'font size',
      default: '0.8rem',
    }

  },
  computed: {
    venue: {
      get() {
        return this.title ? venueDecoder(this.title) : ''
      }
    },
  },
}


</script>
