<template>
  <div
    class="centered"
    style="width: 100%; "
    :style="{
              background: `linear-gradient(${color}, black)`,
            }">
    <ResponsiveImage  :image="image" :clamp="clamp" :alt="title" :align="align"/>


    <div :class="align">
      <h1
        class="tulum-party-title "
        :style="{ textTransform: textTransform,}"

        style="
             line-height: 1.2;
                margin: 20px;
                font-size: 1.8rem;
                padding: 20px;
              "
      >
        {{ title }}
      </h1>
      <p class="tulum-party-title "
         style="font-size: 0.8rem; margin: 20px;  padding: 20px;margin-top:-60px;font-weight: normal;text-transform:capitalize">
        {{ caption }}
      </p>
    </div>

  </div>
</template>
<script>
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage";

export default {
  name: 'HeadImage',
  components: {ResponsiveImage},
  props: {
    image: {
      type: String,
      default: ''
    },
    clamp: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'text-left'
    },
    textTransform: {
      type: String,
      default: 'capitalize'
    },
    title: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
  }
}
</script>
