<template>
  <div v-if="brand">
    <br>
    <router-link
      :to="{ name: 'Brand', params: { slug: brand.slug } }"
      class="btn-link btn-sm"
      :style="{fontSize: this.fontSize}"
      style=" padding:15px" >
     ™️ PRESENTED BY: {{ brand.title.toUpperCase() }}
    </router-link>
  </div>


</template>
<script>
import {brandDecoder} from "@/common/Decoder";

export default {
  name: 'EventBrand',
  props: {
    title: {
      type: String,
      description: 'Event title',
    },

    fontSize: {
      type: String,
      description: 'font size',
      default: '0.8rem',
    }
  },
  computed: {
    brand: {
      get() {
        return brandDecoder(this.title);
      }
    },
  },
}


</script>
