<template>
  <div :align="align">
    <img
      v-lazy="`${image}?w=${ws}&h=${hs}&${imgix_params}`"
      style="
        margin-top: -10px;
        opacity: 0.8;
        z-index: 12;
        box-shadow: 0px 10px 15px 0px rgb(0 0 0.6);
        border-radius: 0;
      "
      v-bind:alt="alt"
      :width="ws + 'px'"
      :height="hs + 'px'"
      class="d-inline-xs d-sm-none"
    />
    <img
      v-lazy="`${image}?w=${wl}&h=${hl}&${imgix_params}`"
      style="
        margin-top: -10px;
        opacity: 0.8;
        z-index: 12;
        box-shadow: 0px 10px 15px 0px rgb(0 0 0.6);
        border-radius: 0;
      "
      v-bind:alt="alt"
      :width="wl + 'px'"
      class="d-none d-sm-inline"
      :height="hl + 'px'"
    />
  </div>
</template>
<script>
export default {
  name: 'ResponsiveImage',
  computed: {
    imgix_params: {
      get() {
        let with_clamp = this.mono ? 'fit=clamp&monochrome=817373' :'fit=clamp&auto=enhance' ;
        let no_clamp = this.mono ? 'fit=fillmax&fill=blur&auto=enhance&monochrome=817373' : 'fit=fillmax&fill=blur&auto=enhance';
        return this.clamp ? with_clamp : no_clamp;
      },
    },
  },
  props: {
    image: {
      type: String,
      default: '',
    },
    ws: {
      type: String,
      default: '315',
    },
    hs: {
      type: String,
      default: '200',
    },
    wl: {
      type: String,
      default: '615',
    },
    hl: {
      type: String,
      default: '300',
    },
    clamp: {
      type: Boolean,
      default: false,
    },
    mono: {
      type: Boolean,
      default: false,
    },
    align: {
      type: String,
      default: 'text-left',
    },
    alt: {
      type: String,
      default: '',
    },
  },
};
</script>
